import '~/scripts/integrations/jquery-extended';

R.ui.initWYSIWYG = function($targetEl, inputFormatOpts, isForComments, mentionTaggingEnabled) {
  var INIT_EVENT = 'tbwinit',
      MODAL_OPEN_EVENT = 'tbwmodalopen',
      MODAL_FOCUS_EVENT = 'tbwfocus',
      MODAL_CLOSE_EVENT = 'tbwmodalclose';
  var btnSelector = '.trumbowyg-button-pane button';

  var config = getConfig();
  var imageUploadPath = config.image_upload_path;
  var editorSettings = config.editor_settings || {};
  const apiKeyForGif = config.gif_api_key;
  const gifSource = config.gif_source;

  const buttonList = getButtons(editorSettings, apiKeyForGif, gifSource);
  const pluginOptsList = getPluginOpts(editorSettings, imageUploadPath, apiKeyForGif, gifSource);
  if (window.R.MentionTagging){ // TODO: integrate for outlook
    mentionTaggingEnabled = (typeof mentionTaggingEnabled === 'undefined') ? true : mentionTaggingEnabled;
  } else {
    mentionTaggingEnabled = false;
  }

  // update input format (hidden field) to html on editor focus event
  // NOTES: - using focus instead of init event because when using init event, the input is not formatted by the editor
  //          which is problematic for edit forms with text input_format.
  //        - the currentFormat check doesn't have functional purpose, as the input will be update to the same value anyways
  //          it is relevant for edit views only when existing data format is already html
  // TODO (maybe): currentFormat can probably be deduced from $elem itself
  var wysiwygFormat = 'html';
  if (inputFormatOpts && inputFormatOpts.currentFormat !== wysiwygFormat) {
    $targetEl.one(MODAL_FOCUS_EVENT, function () {
      inputFormatOpts.$elem.val(wysiwygFormat);
    });
  }

  $targetEl.trumbowyg({
    // Note: this file name should be updated when the icons are updated, to avoid caching issue (since it's not processed by vite)
    svgPath: '/assets/3p/trumbowyg/icons-2024.svg',
    btns: buttonList,
    autogrow: true,
    minimalLinks: true,
    defaultLinkTarget: '_blank',
    removeformatPasted: true,
    plugins: pluginOptsList
  })
  // change tooltip placements from bottom to top so they are shown outside the editor
  .on(INIT_EVENT, function () {
    var $buttons = $(this).closest('.trumbowyg-box').find(btnSelector);
    if (mentionTaggingEnabled && editorSettings['allow_user_mentions']){
      new R.MentionTagging($(this), true);
    }
  })

  /**
   * Minor UI fix: Disable toolbar tooltips when a modal is open, and restore it back when the modal is closed
   * Comments Note:
   *   This is not necessary for comments since an overlay is shown in those cases
   *   it is also not performant in that case since it targets buttons for all cards
   *   More importantly, comments are loaded async (usually after tooltip init) so the editor only shows the browser's native tooltip
   */
  if (!isForComments) {
    $targetEl
      .on(MODAL_OPEN_EVENT, function () { $(btnSelector).tooltip('disable') })
      .on(MODAL_CLOSE_EVENT, function () { $(btnSelector).tooltip('enable') });
  }

  /*
   * Using data attr to prevent mass duplication of callback for comments wysiwyg
   *
   * This needs to be registered globally on $document since the modal is dynamically inserted later on
   * it cannot be defined on the $body for some reason as the form-loading reset does not happen that way
   * this is de-registered in destroyWYSIWYG method below
   **/
  var modalListenerCheckAttr = 'wysiwygModalSubmitListenerRegistered';
  if (!$body.data(modalListenerCheckAttr)) {
    $document.on(R.touchEvent, '.trumbowyg-modal .trumbowyg-modal-submit', wysiwygModalSubmitListener);
    $body.data(modalListenerCheckAttr, true);
  }

  function wysiwygModalSubmitListener(e) {
    /*
     * Form-loading fix
     * The trumbowyg uploader does not send ajax request when the input is empty (due to internal validation)
     * so we need to manually revert the loading status for submit buttons when the form is submitted that way
     **/
    var $form = $(this).closest('form');
    var $input = $form.find('input[name="url"], input[name="file"]');
    if ($input.length && !$input.val().trim()) {
      resetFormLoadingButton();
    }

    /**
     * basic validation for URLs
     * Note: Using a non-robust approach of filtering by modal title, because trumbowyg does not add specific selectors
     *       the alternative would be to just ignore a modal that doesn't have the target input element, inside validation
     */
    var modalTitle = $(this).closest('.trumbowyg-modal').find('.trumbowyg-modal-title').text();
    if (['Insert Image', 'Insert link'].indexOf(modalTitle) !== -1) {
      if (!validateUrlInputInModal($form.closest('.trumbowyg-modal')))
        e.preventDefault();
    }
  }

  function getPluginOpts(settings, uploadPath, gifApiKey, gifSource) {
    var pluginOpts = {};

    if (settings['allow_uploading_images']) {
      pluginOpts['upload'] = {
        serverPath: uploadPath,
        fileFieldName: 'file', // param key for uploaded file
        error: errorCallback
      };
    }

    const gifOpts = { apiKey: gifApiKey }
    // FIXME: hack the rating to limit results (for perf)
    // until Trumbowyg supports additional parameters
    // https://github.com/Alex-D/Trumbowyg/issues/1436
    if (gifSource === 'giphy') {
      gifOpts['rating'] = 'pg';
    } else if (gifSource === 'tenor') {
      gifOpts['contentFilter'] = 'medium'; // equivalent to pg
    }

    pluginOpts[gifSource] = gifOpts;
    return pluginOpts;
  }

  // returns true if valid, otherwise adds validation message and returns false
  function validateUrlInputInModal($modal) {
    var url = $modal.find('input[name="url"]').val();

    /*
     * Perform basic url validation, only allowing http links, so that:
     *  - Data urls and local file paths are rejected, among others.
     *  - The links actually work in the browser. Without the protocol, the browser prefixes the current path to the link
     * regex extended from https://stackoverflow.com/a/15734347
     **/
    var urlRegex = /^https?:\/\/[^ "]+\.[^ "]+$/;
    if (!url.trim() || urlRegex.test(url))
      return true;

    clearPreviousErrorMessages($modal);
    var urlErrors = getUrlErrorMessages();
    // show specific error message when applicable
    var errorMessage = (url.lastIndexOf('http', 0) === -1) ? urlErrors.must_start_with_http : urlErrors.is_invalid;
    displayCustomErrorInModal(errorMessage, $modal);
    resetFormLoadingButton();

    return false;
  }

  function getButtons(settings, gifApiKey, gifSource) {
    // default set
    var buttons = ['strong', 'em'];
    if (!R.utils.isIE()){
      buttons.push('emoji'); // this does not seem to be supported in IE
    }

    // link button
    if (settings['allow_links']) {
      buttons.push('link');
    }

    // image buttons
    if (settings['allow_inserting_images']) {
      buttons.push('insertImage');
    }
    if (settings['allow_uploading_images']) {
      buttons.push('upload');
    }

    // GIF button
    if (settings['allow_gif_selection'] && gifApiKey) {
      buttons.push(gifSource); // giphy or tenor
    }

    return buttons;
  }

  function errorCallback(xhr, _textStatus, _errorThrown) {
    // there is no reference available to the originating modal element here, so need to fetch it from DOM
    var $modal = $('.trumbowyg-modal');

    clearPreviousErrorMessages($modal);
    if (xhr.responseJSON && xhr.responseJSON.message){
      var errorMessage = xhr.responseJSON.message.trim();
      errorMessage = errorMessage.replace(/^\^/, ''); // remove caret prefix. This is usually handled by JsonResource
      displayCustomErrorInModal(errorMessage, $modal);
    } else {
      // this is the default behavior (apart from the status mapping),
      // which is no longer present when error callback is specified
      var errorMap = { 401: 'Not authorized', 422: 'Invalid file' };
      var errorTitle = errorMap[xhr.status] || 'Error';
      var $defaultError = $('<span>', {class: 'trumbowyg-msg-error', text: errorTitle});
      var $fileInputContainer = $modal.find('input[type="file"]').parent().siblings('.trumbowyg-input-infos').find('label');
      $defaultError.appendTo($fileInputContainer);
      $fileInputContainer.parents('.trumbowyg-input-row').addClass('trumbowyg-input-error');
    }

    // this is only needed for erroneous cases, as the modal closes upon success.
    resetFormLoadingButton();
  }

  function clearPreviousErrorMessages($modal) {
    var $customError = $modal.find('.trumbowyg-custom-error');
    if ($customError.length) {
      $customError.remove();
      var originalHeight = $modal.data('original-height');
      if (originalHeight) { $modal.height(originalHeight); }
    }

    var $defaultError = $modal.find('.trumbowyg-msg-error');
    if ($defaultError.length) {
      $defaultError.closest('label').removeClass('trumbowyg-input-error');
      $defaultError.remove();
    }
    return $customError;
  }

  function displayCustomErrorInModal(errorMessage, $modal) {
    var $customError = $('<div>', {class: 'trumbowyg-custom-error', text: errorMessage});
    $customError.prependTo($modal.find('form'));

    var expandedMargin = 20; // margin between title and file input, which overlaps when this custom error is absent
    $modal.data('original-height', $modal.height());
    $modal.height($modal.height() + $customError.outerHeight() + expandedMargin); // reset static modal height
  }

  function resetFormLoadingButton() {
    // FormLoading is currently unavailable in /new_panel path (i.e. Outlook)
    if (window.recognize && window.recognize.patterns && window.recognize.patterns.formLoading) {
      window.recognize.patterns.formLoading.resetButton();
    }
  }

  function getUrlErrorMessages() {
    var defaults = {
      must_start_with_http: 'URL should start with http',
      is_invalid: 'URL is not valid'
    };
    return $.extend(defaults, getConfig().url_error_messages);
  }

  function getConfig() {
    return (typeof gon !== 'undefined' && gon.trumbowyg) || {};
  }
};

R.ui.enableWYSIWYG = function($targetEl) {
 $targetEl.trumbowyg('enable');
}

R.ui.disableWYSIWYG = function($targetEl) {
 $targetEl.trumbowyg('disable');
}

R.ui.destroyWYSIWYG = function($targetEl) {
  if ($targetEl) {
    // Buttons for previously open modals do not work on turbolinks restore
    // as their callbacks are only registered when opening the modal
    $targetEl.trumbowyg('closeModal');
  }

  // This line is extracted from above method's implementation - there is a 100ms delay when this is executed there
  // due to animation, which causes it to be executed on a different page
  $('.trumbowyg-modal').remove();

  if ($targetEl) {
    // Finally destroy the editor so it can be re-initialized on turbolinks restore
    $targetEl.trumbowyg('destroy');
  }

  // remove event listener registered in initWYSIWYG
  $document.off(R.touchEvent, '.trumbowyg-modal .trumbowyg-modal-submit');
};

/**
 * Helper for cards and recognition show page
 * Disables all comment sections in the page when an editor modal is opened, and reverts back when closed
 *
 * This is to prevent issues with multiple simultaneous modals open
 *  - Functional issues
 *    - multiple simultaneous modals are not fully supported in our own implementation of the custom errorCallback
 *      as there is no specific target modal available for that callback
 *    - On some pages like the stream page, the modal positioning is fixed on the screen on scroll,
 *      which results in an overlap when opening another modal
 *  - UX: Having multiple modals doesn't look good in the UI either.
 *    Ideally, bg overlay should've been added on modal open, like done by swal.
 **/
R.ui.watchCommentsWysiwygModalToDisableCommentSections = function (isRecognitionShowPage) {
  var overlayClass = 'blur-overlay';
  // note: the second class here is also used in Cards.js to blur out paginated cards on load
  var wrapperSel = isRecognitionShowPage ? '.comments-wrapper' : '.comment-wrapper';
  $body.on('tbwmodalopen', '.comment_content', function (_e) {
    // using setTimeout just to make original modal action faster
    setTimeout(function(){
      $(wrapperSel).addClass(overlayClass);
      if ($('#users-show').length) // for user profile - don't allow tab switching
        $('.nav-pills').addClass(overlayClass);
    });
  });
  $body.on('tbwmodalclose', '.comment_content', function (_e) {
    // using setTimeout just to make original modal action faster
    setTimeout(function() {
      $(wrapperSel).removeClass(overlayClass);
      if ($('#users-show').length)
        $('.nav-pills').removeClass(overlayClass);
    });
  });
};
