import '~/scripts/integrations/jquery-extended';

(function() {
  var $badgeList;
  window.R.ui.BadgeList = BadgeList;

  var $badgeEdit;

  BadgeList.prototype = {
      addEvents: function() {
          var that = this;
          this.$badgeTrigger = $("#top .image-wrapper");
          this.$badgeTrigger = this.$badgeTrigger.length > 0 ? this.$badgeTrigger : $("form .image-wrapper");
          $badgeList = $('#badge-list');

          var isNotEditForm = !$("form").hasClass('edit_recognition');
          if (isNotEditForm) {
            this.$badgeTrigger.bind("click", function (e) {
              e.preventDefault();

              R.transition.fadeTop("#recognize-badge-list-wrapper");

              $("#best-wrapper").show();

            }.bind(this));
          }

          $document.on("click", ".badge-item .button", function() {
              $(this).closest(".badge-item").click();
          });

          $document.on("click", ".badge-item", function(e) {
              var $this = $(this);
              var $badge = $this.find(".badge-image-small");
              var badge = {
                  id: $badge.data("badge-id"),
                  name: $badge.data("name"),
                  relativeImagePath: $badge.data("relativeimagepath"),
                  minPoints: $badge.data('minPoints'),
                  maxPoints: $badge.data('maxPoints')
              };
              $("#recognition_badge_id").val(badge.id);
              $("#nomination_badge_id").val(badge.id);
              $(".image-wrapper").addClass("chosen");
              $('#recognitionPoints').val(badge.minPoints);

              that.chooseBadge($this);

              if (that.type === 'recognition') {
                that.handleForcedPrivacy($this);
              }
          });

          $document.on("click", "#badge-list .badge-image-small", function(e) {
              $(this).siblings(".button").trigger(R.touchEvent);
          });
      },

      removeEvents: function() {
          if (this.$badgeTrigger) { this.$badgeTrigger.unbind("click"); }
          $document.off('click', '.badge-item');
          $document.off('click', '.badge-item .button');
          $document.off('click', '#badge-list .badge-image-small');
      },

      closeOverlays: function() {
          R.transition.fadeTop();
      },

      chooseBadge: function($badgeButton) {
          $window.trigger('badgeAdded', {points: $badgeButton.data('points')});

          if ( $badgeButton.attr("id") === "upgrade-badges-link-badge" ) {
              return Turbo.visit($badgeButton.prop('href'));
          }

          var $badge = $badgeButton.find(".badge-image-small");
          var id = $badge.data("badge-id");
          var badge = {
              name: $badge.data("name"),
              className: $badge.data("cssclass"),
              relativeImagePath: $badge.data("relativeimagepath")
          };

          R.BadgesRemaining.setOriginalButtonInBadgeList();

          $badgeButton.data('interval', $badgeButton.data('interval'));
          setBadgeLimit($badgeButton);

          var largeBadgeWrapper = this.$badgeTrigger.find("#badge-trigger")[0];

          $("#"+this.type+"_badge_id").val(id);
          $(".text-secondary").removeClass("edit");

          $badgeEdit = get$badgeEdit();
          $badgeEdit.removeClass("d-none");
          $("#chosenBadgeImage").removeClass("d-none");
          $badge.data('display', $badgeButton.data('display'));
          $('.recognition-points-with-badge-quantity .badges-remaining-wrapper').data('badgeid', id);
          $('#badgesLeftAtInterval').data('quantity', $badgeButton.data('quantity'));
          PointsBoxBehavior($badge);
          this.$badgeTrigger.addClass("edited");

          // largeBadgeWrapper.className = "badge-image "+badge.className;
          largeBadgeWrapper.className = "";
          var $badgeImage = $("#chosenBadgeImage");
          $badgeImage.attr('src',  badge.relativeImagePath);
          $($badgeImage).replaceWith($badgeImage);

          if ($html.hasClass("ie8")) {
              largeBadgeWrapper.className = largeBadgeWrapper.className.replace("badge-image", "badge-image-small");
          }

          $("#badge-name").text(badge.name).removeClass("text-secondary");

          if ($window.width() <= 690) {
              $("body, html").scrollTop(0);
          }
          this.closeOverlays();
      },

      handleForcedPrivacy: function ($badgeItem) {
        this.$privacyInput = this.$privacyInput || $('input[name="recognition[is_private]"]');
        if (!this.$privacyInput.length) { return }

        this.privacyEnforcer = this.privacyEnforcer || new RecognitionPrivacyEnforcer(this.$privacyInput);
        var privacyEnforced = this.privacyEnforcer.isActive();
        var newBadgeForcesPrivacy = ($badgeItem.data('force-private') === true);

        if (newBadgeForcesPrivacy && !privacyEnforced) {
          this.privacyEnforcer.activate();
        } else if (!newBadgeForcesPrivacy && privacyEnforced) {
          this.privacyEnforcer.deactivate();
        }
      }
  };

  function shortIntervalName(interval) {
    // For language other than english-version, return the original interval not short name [returned by switch below for en]
    if (!gon.current_user_locale.includes('en')) { return interval }

    switch(interval) {
      case 'day':
        return 'today';
      case 'month':
        return 'mo';
      case 'year':
        return 'yr';
      case 'week':
        return 'wk'
      case 'quarter':
        return 'qr'
      // Chances of displaying short default interval name is slim, asked to keep default by co-pilot reviewer
      default:
        return 'interval'
    }
  }


  function setBadgeLimit($badgeButton) {
    var recipientUserCount = recipientCount();
    $badgeEdit = get$badgeEdit();
    let badgesLeft = parseInt($badgeButton.data("quantity")) - recipientUserCount;
    $badgeButton.find(".badge-quantity").text(badgesLeft.toLocaleString(navigator.language));

    var $limitHtml = $badgeButton.find(".badges-remaining-wrapper").clone();
    let $pointsWrapper = $(".recognition-points-with-badge-quantity .points-box-wrapper");
    let $badgesLeftWrapper = $(".recognition-points-with-badge-quantity .badges-remaining-wrapper");
    let $timeInterval = $badgeButton.data("interval");
    var numLeft;

    if ($limitHtml.children().length > 0) {
      if($pointsWrapper.hasClass('w-50')){
        $badgesLeftWrapper.show();
        $pointsWrapper.addClass('col-6').removeClass('w-50 mx-auto');
      }

      numLeft = parseInt($badgeButton.data("quantity")) - recipientUserCount;
      let formattedBadgesLeft = numLeft.toLocaleString(navigator.language);

      if (numLeft < 0) {
        $limitHtml.addClass("text-danger");
      } else {
        $limitHtml.removeClass("text-danger");
      }

      $('#badgesLeftAtInterval').text(`${formattedBadgesLeft}/${shortIntervalName($timeInterval.toLowerCase())}`)
    } else {
      // hide quantity from default badges
      $badgesLeftWrapper.hide();
      $pointsWrapper.removeClass('col-6').addClass('w-50 mx-auto');
    }
  }

  function selectDiffTypesOfBadges(min, max, maxDisplay, badgesLeftWithInterval) {
    // Margin top control for outlook recognition box's quantity section
    let outlookElement = badgesLeftWithInterval.hasClass('outlook');
    if(outlookElement){
      if(min === max) {
        badgesLeftWithInterval.removeClass('mt-2');
      } else {
        badgesLeftWithInterval.addClass('mt-2');
      }
    } else {
      badgesLeftWithInterval.removeClass('mt-1')
    }

    if(min === max){
      if(gon.point_labels){
        $("#pointsBox label").text(gon.point_labels.points);
      }
      $('#recognitionPoints').addClass("d-none");
      $('#pointsInfo').removeClass(`${outlookElement ? 'text-primary' : 'text-warning'}`);
      $('#pointsInfo').text(min);
    } else {
      $('#pointsInfo').addClass(`${outlookElement ? 'text-primary' : 'text-warning'}`);
      $('#pointsInfo').removeClass('d-none');
      $("#pointsBox label").text(gon.point_labels.adjust_points);
      $('#recognitionPoints').attr('min', min);
      $('#recognitionPoints').attr('max', max);
      $('#pointsInfo').text(maxDisplay);
      // Add margin top one for smaller badges count [hackish way as there is less chance of having large count of badges available for a single user]
      if(!(badgesLeftWithInterval.text().split('/')[0].length > 6) && !badgesLeftWithInterval.hasClass('outlook')) {
        badgesLeftWithInterval.addClass('mt-1');
      }
    }
  }

  function PointsBoxBehavior($badge) {
    let minPoints = $badge.data('min-points');
    let maxPoints = $badge.data('max-points');
    let maxDisplay = $badge.data('display');
    let badgesLeftWithInterval = $('#badgesLeftAtInterval');

    // If remaining points balance is lesser than badge maximum points, make pointLeft as max
    // Additionally sets recognition max 0 for user with negative sendable balance
    if (window.pointsLeft < maxPoints) {
      maxPoints = window.pointsLeft
    }

    $('.recognition-points-with-badge-quantity').removeClass('d-none');
    $('#recognitionPoints').removeClass('d-none');

    selectDiffTypesOfBadges(minPoints, maxPoints, maxDisplay, badgesLeftWithInterval);
  }


  function BadgeList(type) {
    this.type = type || "recognition";
    this.addEvents();
  }

  function recipientCount() {
    var userCount = $("#chosen-recepient-wrapper .recipient-wrapper:not(.team)").length;
    var teamCount = 0;

    $("#chosen-recepient-wrapper .recipient-wrapper.team").each(function() {
      teamCount += $(this).data("count") || 0;
    });

    return userCount + teamCount;
  }

  function get$badgeEdit() {
    return $("#badge-edit");
  }
})();
