import '~/scripts/integrations/jquery-extended';

(function() {
  function PointsRemaining() {
    this.addEvents();
    this.currentRemainingPoints = window.pointsLeft;
    this.totalPoints = window.totalPoints;
  }

  PointsRemaining.prototype = {
    updateUI: function(points) {
      this.currentRemainingPoints = window.pointsLeft - points;
      var $progressBar = $('.progress-bar-points');
      var percentage = this.currentRemainingPoints/window.totalPoints, percentage = (percentage*100).toFixed(2);
      var formattedNumber = this.currentRemainingPoints.toLocaleString(navigator.language);
      var pointsLabelHTML = $('.remainingPointToGiveSummary .pointValueWithLabel')
      $progressBar.css({width: percentage+'%'}).prop('aria-valuenow', this.currentRemainingPoints);
      pointsLabelHTML.html(formattedNumber);

      if (points > 0) {
        pointsLabelHTML.first().parent().bounce();
      }
    },
    addEvents: function() {
      var that = this;
      $window.off('updatePointsRemaining');
      $window.on('updatePointsRemaining', function(e, data) {
        that.updateUI(data.points);
      });
    }
  };

  window.R.ui.PointsRemaining = PointsRemaining;
})();
