// https://raw.githubusercontent.com/byn9826/words-count/master/src/globalWordsCount.js
// This file was manually transpiled to ES5 to support Older Outlooks using IE.
// Tool used for transpiling: https://jstool.gitlab.io/babel-es6-to-es5/

var DEFAULT_PUNCTUATION = [
	',', '，', '.', '。', ':', '：', ';', '；', '[', ']', '【', ']', '】', '{', '｛', '}', '｝',
	'(', '（', ')', '）', '<', '《', '>', '》', '$', '￥', '!', '！', '?', '？', '~', '～',
	"'", '’', '"', '“', '”',
	'*', '/', '\\', '&', '%', '@', '#', '^', '、', '、', '、', '、'
];

var EMPTY_RESULT = {
	words: [],
	count: 0
}

var wordsDetect = function wordsDetect(text) {
	var config = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : {};
	if (!text) return EMPTY_RESULT;
	var words = String(text);
	if (words.trim() === '') return EMPTY_RESULT;
	var punctuationReplacer = config.punctuationAsBreaker ? ' ' : '';
	var defaultPunctuations = config.disableDefaultPunctuation ? [] : DEFAULT_PUNCTUATION;
	var customizedPunctuations = config.punctuation || [];
	var combinedPunctionations = defaultPunctuations.concat(customizedPunctuations);
	// Remove punctuations or change to empty space
	combinedPunctionations.forEach(function (punctuation) {
		var punctuationReg = new RegExp('\\' + punctuation, 'g');
		words = words.replace(punctuationReg, punctuationReplacer);
	});
	// Remove all kind of symbols
	words = words.replace(/[\uFF00-\uFFEF\u2000-\u206F]/g, '');
	// Format white space character
	words = words.replace(/\s+/, ' ');
	// Split words by white space (For European languages)
	words = words.split(' ');
	words = words.filter(word => word.trim());
	// Match latin, cyrillic, Malayalam letters and numbers
	var common = '(\\d+)|[a-zA-Z\u00C0-\u00FF\u0100-\u017F\u0180-\u024F\u0250-\u02AF\u1E00-\u1EFF\u0400-\u04FF\u0500-\u052F\u0D00-\u0D7F]+|';
	// Match Chinese Hànzì, the Japanese Kanji and the Korean Hanja
	var cjk = '\u2E80-\u2EFF\u2F00-\u2FDF\u3000-\u303F\u31C0-\u31EF\u3200-\u32FF\u3300-\u33FF\u3400-\u3FFF\u4000-\u4DBF\u4E00-\u4FFF\u5000-\u5FFF\u6000-\u6FFF\u7000-\u7FFF\u8000-\u8FFF\u9000-\u9FFF\uF900-\uFAFF';
	// Match Japanese Hiragana, Katakana, Rōmaji
	var jp = '\u3040-\u309F\u30A0-\u30FF\u31F0-\u31FF\u3190-\u319F';
	// Match Korean Hangul
	var kr = '\u1100-\u11FF\u3130-\u318F\uA960-\uA97F\uAC00-\uAFFF\uB000-\uBFFF\uC000-\uCFFF\uD000-\uD7AF\uD7B0-\uD7FF';

	var reg = new RegExp(
		common + '[' + cjk + jp + kr + ']',
		'g'
	);
	var detectedWords = [];
	words.forEach(function (word) {
		var carry = [];
		var matched;
		do {
			matched = reg.exec(word);
			if (matched) carry.push(matched[0])
		} while (matched);
		if (carry.length === 0) {
			detectedWords.push(word);
		} else {
			detectedWords = detectedWords.concat(carry);
		}
	});
	return {
		words: detectedWords,
		count: detectedWords.length
	};
}

var wordsCount = function wordsCount(text) {
  var config = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : {};
  var wordsInText = wordsDetect(text, config);
  return wordsInText.count;
};

var wordsSplit = function wordsSplit(text) {
  var config = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : {};
  var wordsInText = wordsDetect(text, config);
  return wordsInText.words;
};

window.wordsDetect = wordsDetect
window.wordsCount = wordsCount;
window.wordsSplit = wordsSplit;
